import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReportIcon from '@mui/icons-material/Report';
import { Dialog, Grid, IconButton, Paper, Stack, TablePagination, Typography, } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from "./Dailogsandpopups/Delete";
import { Button, Container } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from '@mui/material/LinearProgress';
import { Alert } from "./Context/index.js";

function Homepage() {
  const alertforhome = React.useContext(Alert)
  console.log("con", alertforhome)
  const history = useNavigate();
  const ref = useRef(true)
  const [dataload, setDataload] = React.useState([]);
  const [deleteid, setDeleteid] = React.useState("");
  const [deletedialog, setDeletedialog] = React.useState(false);
  const [filterdataload, setFilterDataload] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [timeout, setTimeout] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [open, setOpen] = React.useState(false);
  // const [alertmsg, setAlertmsg] = React.useState("");
  // const [alertstatus, setAlertStatus] = React.useState("");



  // const [getfiles, setGetfiles] = React.useState([]);
  const reportPage = () => {
    history("/steps");
  };

  //search function
  // const [search, setsearch] = React.useState("");
  const handleSearch = (event) => {
    // debugger
    var temp = event.target.value;

    // setsearch(temp);
    if (temp !== "") {
      const filter = dataload.filter((v) => {
        return (
          v.apiname.toLowerCase().includes(temp.toLowerCase()) ||
          v.apitype.toLowerCase().includes(temp.toLowerCase()) ||
          v.httpmethod.toLowerCase().includes(temp.toLowerCase()) ||
          v.url.toLowerCase().includes(temp.toLowerCase()) ||
          v.endpointpath.toLowerCase().includes(temp.toLowerCase()) ||
          v.apiusage.toLowerCase().includes(temp.toLowerCase())
        );
      });
      setFilterDataload(filter);
    } else {
      setFilterDataload(dataload);
    }

  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  var update = localStorage.getItem("reload");
  const getreaddoc=async()=>{ var temp1 = {
    db_name: process.env.REACT_APP_DB_NAME,
    entity: process.env.REACT_APP_ENTITY,
    filter: `${process.env.REACT_APP_ENTITY
      }.metadataid=='${sessionStorage.getItem("metadataId")}'`,
    return_fields: process.env.REACT_APP_ENTITY,
  };
  var config1 = {
    method: "post",
    url: process.env.REACT_APP_API_URL,
    headers: { "Content-Type": "application/json" },
    data: temp1,
  };
 await axios(config1)
    .then((res) => {
      ref.current = false
      console.log("homepage", res.data.result, ref);
      // debugger;
      setDataload(res.data.result);
      setFilterDataload(res.data.result);
    })
    .catch((err) => {
      console.error(err);
    });
  }
  React.useEffect(() => {
    getreaddoc();
    localStorage.setItem("reload", "false");
    // }
  }, [update, alertforhome.open]);

  /////Delete dailog open function////
  const handledelete = (v) => {
    setDeletedialog(true);
    console.log("v._key", v._key);
    setDeleteid(v._key);
    // console.log(v);
  };


  // const timeoutAlert = (e) ={
  //   timeoutId = setTimeout(() => {
  //     setIsLeaving(false);
  //     setIsShown(false);
  //   }, timeout);
  // };

  /////delete function///
  const deletefunc = async(e) => {
    // e.preventDefault()
    var temp = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: process.env.REACT_APP_ENTITY,
      filter: `${process.env.REACT_APP_ENTITY}._key=='${deleteid}'`,
    };
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL_DELETE,
      headers: { "Content-Type": "application/json" },
      data: temp,
    };
  const asdf=await axios(config)
      .then(async(res) => {
        alertforhome.setAlert({ open: true, alertvalue: "Successfully deleted  the record !", timeout: 2500, severity: "success" })
        getreaddoc();
      })


      .catch((err) => {
        console.log(err.data);
      });
      
    setDeletedialog(false);
  };
  

  

  /////deletepopupclose function/////
  const handleClosefordeletepopup = () => {
    setDeletedialog(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          {ref.current ?
            <LinearProgress /> :

            <div>
              <Paper
                style={{
                  boxShadow: "3px 3px 10px 2px rgba(0, 0, 0, 0.25)",
                  padding: "10px",
                }}
              >
                <Grid container direction={"row"}>
                  <Grid item xs={9}>
                    <Typography
                      style={{
                        fontWeight: "700",
                        fontFamily: "Arial",
                        margin: "3px",
                        padding: "2px",
                      }}
                    >
                      Service Registry
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <input
                      style={{ width: "200px", height: "25px", marginLeft: "-10px", marginTop: "5px", marginBottom: "5px" }}
                      id="standard-bare"
                      variant="outlined"
                      placeholder="search"
                      onChange={handleSearch}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      onClick={() => {
                        reportPage();
                      }}
                      style={{
                        backgroundColor: "#00205C",
                        color: "white",
                        margin: "3px 5px",
                        zIndex: "0",
                        textTransform: " capitalize",
                      }}
                    >
                      Add+
                    </Button>
                  </Grid>
                </Grid>

                <TableContainer
                  style={{
                    borderRadius: "15px",
                    borderBottom: "1.5px solid grey ",
                    borderLeft: "1.5px solid grey ",
                  }}
                  sx={{ maxHeight: 450 }}
                >
                  <Table stickyHeader={true} size="small">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell
                        style={{
                          color: "white",
                          backgroundColor: "#00205C",
                          borderBottom: "0px",
                          fontFamily: "Arial",
                          height: "33px",
                        }}
                      >
                        ID
                      </TableCell> */}
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          API Name
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          API Type
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          Http Method Type
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          URL
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          End Point
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          API Usage
                        </TableCell>

                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          style={{
                            color: "white",
                            backgroundColor: "#00205C",
                            borderBottom: "0px",
                            fontFamily: "Arial",
                            height: "33px",
                          }}
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterdataload.length !== 0 ?
                        filterdataload.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((v) => {
                          console.log(v.id);
                          return (
                            <TableRow key={v.id}>
                              {/* <TableCell>{v.id}</TableCell> */}
                              <TableCell>{v.apiname}</TableCell>
                              <TableCell>{v.apitype}</TableCell>
                              <TableCell>{v.httpmethod}</TableCell>
                              <TableCell>{v.url}</TableCell>
                              <TableCell>{v.endpointpath}</TableCell>
                              <TableCell>{v.apiusage}</TableCell>

                              <TableCell>
                                <IconButton
                                  onClick={() => history("/edit", { state: v })}
                                >
                                  <EditIcon fontSize="small" color="info" />
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    handledelete(v);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <DeleteIcon fontSize="small" color="error" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }) :
                        <Stack style={{ padding: "50px" }} direction={"row"}>

                          <ReportIcon fontSize="large" style={{ color: "red" }} />
                          <Typography style={{ color: "#01205c", fontWeight: "30px", fontSize: "28px" }} >Sorry!! No Data Found.  </Typography>
                        </Stack>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={filterdataload.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ fontFamily: "Arial" }}
                padding="false"
              />
            </div>
          }

          <Dialog
            open={deletedialog}
            onClose={() => {
              setDeletedialog(false);
            }}
          >
            <Delete
              handleClosefordeletepopup={handleClosefordeletepopup}
              deletefunc={(e) => deletefunc(e)}
            />
          </Dialog>
        </Container>
      </div>
    </>
  );
}

export default Homepage;