import React from 'react'
import { Alert } from './Context/index.js'
import Alerts from './Dailogsandpopups/Alerts'

function Appalert(props) {
    const [alert, setAlert] = React.useState({
        open: "",
        autoHideDuration: 3000,
        onClose: ()=>null,
        severity: "",
        alertvalue: "",
    })
    const onclose=()=>{
        setAlert({open:false})
    }
  return (
        <Alert.Provider value={{ alert, setAlert }}>
           {alert.open&& <Alerts open={alert.open} autoHideDuration={alert.autoHideDuration} onClose={onclose} severity={alert.severity} alertvalue={alert.alertvalue} />}
           {props.children}
           
        </Alert.Provider>
    )
}

export default Appalert