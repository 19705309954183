import React from 'react'
import Home from './home'
import Appalert from './App.alert.js'

function App() {
    
    return (
        <Appalert>
            <Home/>
        </Appalert>
    )
}

export default App
