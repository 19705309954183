import React, { useRef ,useEffect} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Divider from "@mui/material/Divider";
import toJsonSchema from "to-json-schema";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router";
import { Grid, Stack } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArangoDBAPI from "./ArangodbApi";
import { Alert } from "./Context";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';


const Alerts = React.forwardRef(function Alerts(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const steps = ["Register", "Request", "Response"];

function Stepform() {
  const alertforhome = React.useContext(Alert)
  const navigation = useNavigate();
  const ref = useRef(true)
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [schema, setSchema] = React.useState(
    JSON.stringify(location?.state.requestschema, undefined, 4)
  );
  const [open1, setOpen1] = React.useState(false);
  const [alertmsg, setAlertmsg] = React.useState("");
  const [alertmsg1, setAlertmsg1] = React.useState("");
  const [template, setTemplate] = React.useState({
    id: location?.state.id,
    apiname: String(location?.state.apiname),
    apitype: String(location?.state.apitype),
    httpmethod: String(location?.state.httpmethod),
    url: String(location?.state.url),
    endpointpath: String(location?.state.endpointpath),
    apiusage: String(location?.state.apiusage),
  });
  const [fetchdata, setFetchdata] = React.useState([]);
  const [fetchdata1, setFetchdata1] = React.useState([]);

  React.useEffect(()=>{
    handleFetch() 
    handleFetch1();
    },[])


console.log("qqaa",Array.isArray(location?.state.request) &&( location?.state.endpointpath==="upert_document"))
  const [alertstatus, setAlertStatus] = React.useState("");
  const [alertstatus1, setAlertStatus1] = React.useState("");
  const [newquery1, setNewQuery1] = React.useState("");
  const [readquery, setReadquery] = React.useState(
    // (typeof(location?.state.request)==="string")?JSON.stringify(JSON.parse(location?.state.request ),undefined,4):    
    (typeof (location?.state.request) === "string") ? ((location?.state.request[0])) :
     ( (Array.isArray(location?.state.request) &&( location?.state.endpointpath==="upsert_document")) )? JSON.stringify(location?.state.request, undefined, 4):location?.state.request[0]
  );
  console.log("check array", typeof (location?.state.request), (typeof (location?.state.request) === "string") ? "norun" :

    JSON.stringify(location?.state.request, undefined, 4))
  const [open, setOpen] = React.useState(false);
  const [apidata, setApidata] = React.useState(

   // (typeof (location?.state.response) === "string") ? JSON.stringify(JSON.parse(location?.state.response), undefined, 4) :

      JSON.stringify(location?.state.response, undefined, 4)
    //JSON.stringify(location?.state.response, undefined, 4)
  );
  console.log("check array", typeof (location?.state.response), (typeof (location?.state.response) === "string") ? "norun" :

    JSON.stringify(location?.state.response, undefined, 4))
  const [resschema, setReschema] = React.useState(
    JSON.stringify(location?.state.responseschema, undefined, 4)
  );
  const [newquery, setNewQuery] = React.useState(
    JSON.stringify(location?.state.apirequest, undefined, 4)
  );

  const [ispayload, setispayload] = React.useState(location?.state.ispayload)

  ///validating url using regex
 const validurl = new RegExp("((http|https)://)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")
  //const validurl = new RegExp("")

  const onchangeres = (v) => {
    setNewQuery(v)
    setApidata(v)
    if (apidata?.length === 0) {
      setReschema("")
    }
  };



  ///toggle switch 
  const handleSwitch = (event) => {
    setispayload(event.target.checked);
  };



  console.log("location", location.state);
  //function for closing snack bar
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    // setActiveStep(0);
    // navigation(-1);
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  //Function for closing snack bar & onclose  return to landing page
  const handleClose1 = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
    setActiveStep(0);
    navigation(-1);
  };
  /////Next button function////

  const handleNext = () => {
       console.log("activestep",activeStep)          
    if (activeStep === 0) {
        
      // alert("first next")

      if (template.apiname.length === 0 ||
        template.apitype.length === 0 ||
        template.httpmethod.length === 0 ||
        template.url.length === 0 ||
        template.endpointpath.length === 0 ||
        template.apiusage.length === 0
      ) {
        alertforhome.setAlert({ open: true, alertvalue: "Mandatory fields cannot be empty", severity: "warning" })
      }
      
      else{
       if(String(location?.state.apiname)!==template.apiname){
        var tempvalid = {
          db_name: process.env.REACT_APP_DB_NAME,
          entity: process.env.REACT_APP_ENTITY,
          filter: `${process.env.REACT_APP_ENTITY}.apiname=='${template.apiname}'`,
          return_fields: process.env.REACT_APP_ENTITY,
        };
        var configvalid = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
          data: tempvalid
        }
        axios(configvalid).then((res) => {
            console.log(res)
            
            if (res.data.result.length === 0) {

              
              
              if (!validurl.test(template.url)) {
                console.log("called url regex")
                setOpen(true);
                setAlertStatus("warning");
                setAlertmsg("Enter valid URL to proceed");
              }
              else {
                if (ispayload === true) {
                  setActiveStep(1);
                }
               
                else {
                  if (apidata?.length === 0) {
                    setOpen(true);
                    setAlertStatus("warning");
                    setAlertmsg(" please enable proper payload")
                  }
                  setActiveStep(2);
                }
              }
            }
            else {
              alertforhome.setAlert({ open: true, alertvalue: "Api Name already exists!!", severity: "warning" })
            }
          })
      }
      else{
        if (!validurl.test(template.url)) {
          console.log("called url regex")
          setOpen(true);
          setAlertStatus("warning");
          setAlertmsg("Enter valid url to Proceed ");
        }
         else if(template.httpmethod ==="Get"&&template.apitype ==="Arango")
          
        { 
          setOpen(true);
          setAlertStatus("error");
          setAlertmsg("Select Proper http Method ")
         }
        
        
        else {
          if (ispayload === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            // setOpen(true);
            // setAlertStatus("success");
            // setAlertmsg("Enter valid payload and click generate");

          }
          
          else {
        
            if (apidata?.length === 0&&alertmsg=="Schema generation failed! please check payload") {
              setOpen(true);
              setAlertStatus("warning");
              setAlertmsg(" Response  generation failed!! please check your payload")
            }
            debugger
            if(template.httpmethod==="Get"
            &&template.apitype==="http"
            ||template.apitype==="https"
            || template.apitype === "Arango")
            {
              // alert("getworking")
              var config = {
                method: "GET",    
                url: template.url+template.endpointpath,    
                header:{}    
              }
              axios(config).then((response) => {    
                console.log(response ,"watch e")
                if (response.data.length !== 0) {
                  setOpen(true);
                  setAlertStatus("success");
                  setAlertmsg(" Response  generated Successfully");
                  // setActiveStep(prevActiveStep=>prevActiveStep+1)
                } else if (response.data.length === 0) {
                  setOpen(true);
      
                  setAlertStatus("warning");
      
                  setAlertmsg(" Response Generation Failed");
                }
                setApidata(JSON.stringify(response))
                })
              
                .catch((error) => {    
                console.error(error)  
                // alert("check.catch") 
                setOpen(true);
                setAlertStatus("error");
                setAlertmsg("Please enable Payload and valid inputs "); 
                setActiveStep(0);
              })
            }
            setActiveStep(2);

          }
          
          }
        }
      }
    }
    if (activeStep === 1
       && readquery.length !== 0
       && template.httpmethod == "Post" 
       && schema.length !== 0 
       && newquery.length !== 0
       &&(template.apitype == "Arango" 
       || template.apitype == "https"
       || template.apitype == "http"))
        {
     
        //  alert("postcalled")

         console.log("sample")
     var isvalidjson = false;
     try {
       JSON.parse(readquery);
       isvalidjson = true;
     } catch (err) {}

     if (isvalidjson == false) {
       setOpen(true);
       setAlertStatus("error");
       setAlertmsg("Enter valid query to Proceed");
     } else {
       var config = {
         method: "post",
         url: template.url + template.endpointpath,
         header: { "Content-Type": "application/json" },
         data: JSON.parse(readquery),
       };
      var config = {
      method: "post",
      url: template.url+ template.endpointpath,
      header: { "Content-Type": "application/json" },
      data: JSON.parse(readquery),
    };
    axios(config)
      .then((res) => {
        console.log("datavalue", res.data.Result);
        
        if(res.length!==0&&res.data.code!==1111){
          setOpen(true);
          setAlertStatus("success");
          setAlertmsg(
            " Response  generated Successfully"
        );
        setActiveStep(2)

        }else if(res.data.length===0||res.data.code==1111) {         

          setOpen(true);

          setAlertStatus("warning");

          setAlertmsg(

            " Response Generation Failed"

        );
           setActiveStep(1)

          }else{
            setOpen(true);

            setAlertStatus("warning");

            setAlertmsg(" Something Went Wrong");
          }
          if(template.apitype==="Arango"){
            setApidata(JSON.stringify(res.data));
        // setApidata(JSON.stringify([{
        //   result: [
        //     template.endpointpath === "upsert_document" ?
        //     res.data.Result[0] : 
        //     res.data.result[0]],

        //   Code: res.data.Code,
        //   query: res.data.query,
        // }]));
        var report = {
          dataSource: {
            data: template.endpointpath === "upsert_document"
             ? res.data.Result
              : res.data.result,
          },

          options: {
            grid: {
              type: "flat",
              showGrandTotals: "off",
              showTotals: "off",
            },
          },
        };
      } else{
        setApidata(JSON.stringify(res.data, undefined, 4));

      }
      if (res.data.Code === 1111) {
        
        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg(res.data.error || res.data.Result, "please check your payload ");
        setActiveStep(1)
      } else {
        setActiveStep(2)

        // setDataload(false);
      }
      

      })
      .catch((err) => {
        setOpen(true);
        setAlertStatus("error");
        //setAlertmsg(err.message);
       setAlertmsg("Please enter proper URL &http method ")
        setActiveStep(1)
      });
      
    }
  }else if(      (readquery=== undefined&& activeStep === 1) ||
  (schema.length === 0 && activeStep === 1) ||
  (newquery.length === 0 && activeStep === 1)){
    
    if (schema.length === 0 && newquery.length === 0) {
    setOpen(true);
    setAlertStatus("warning");
    setAlertmsg("Request Schema/Api Request Cannot be empty");
  } else {
    setOpen(true);
    setAlertStatus("warning");
    setAlertmsg("Please press the generate button");
  }
 }
  }
    

    ///Back button function///
    const handleBack = () => {

      if (ispayload === false) {

        setActiveStep((prevActiveStep) => prevActiveStep - 2);

      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    };



    const handleFetch = () => {
      var senddata
      senddata =
  
      {
        "db_name": "ATP_Platform_DEV",
        "entity": "generalmaster",
        "filter": "generalmaster.activestatus==true AND generalmaster.gentype =='ARANGOENV'",
        "return_fields": "generalmaster"
      }
  
  
      var config = {
        method: "post",
        url: process.env.REACT_APP_API_URL,
        header: { "Content-Type": "application/json" },
        data: senddata,
      };
      axios(config)
        .then((res) => {
          setFetchdata(res.data.result)
  
        })
    }
  
    const handleFetch1 = () => {
      var senddata
      senddata =
  
      {
        
          "db_name": "ATP_Platform_DEV",
          "entity": "generalmaster",
          "filter": "generalmaster.activestatus==true AND generalmaster.gentype =='ARANGODBAPI'",
          "return_fields": "generalmaster"
        
      }
  
  
      var config = {
        method: "post",
        url: process.env.REACT_APP_API_URL,
        header: { "Content-Type": "application/json" },
        data: senddata,
      };
      axios(config)
        .then((res) => {
          setFetchdata1(res.data.result)
  
        })
    }
  








    /////Finish button function///
    const handleFinish = () => {
    
      let sendtodbresponse=JSON.parse(apidata)
      console.log("array",sendtodbresponse[0])
      var readObj=new Array();
      let parsedvalueread=typeof readquery==="string"?JSON.parse(readquery):(readquery) 
      console.log(parsedvalueread)
      // if(typeof(readquery)!="array"){
      //   readObj.push(JSON.parse(readquery));
      // }
      if (resschema.length !== 0) {
      var sendtobackend;
      sendtobackend = {
        db_name: process.env.REACT_APP_DB_NAME,
        entity: process.env.REACT_APP_ENTITY,
        is_metadata: false,
        // metadataId: process.env.REACT_APP_META_DATA_ID,
        // metadata_dbname: process.env.REACT_APP_META_DATA_DB_NAME,
        filter: { _key: location.state._key },
        doc: {
          _id: template.id,
          apiname: template.apiname,
          apitype: template.apitype,
          url: template.url,
          endpointpath: template.endpointpath,
          httpmethod: template.httpmethod,
          request: Array.isArray(parsedvalueread)?(JSON.parse(readquery)):[(readquery)], // readquery.length > 0 ? JSON.parse(readquery) : "",
          requestschema: (schema.length > 0 ? JSON.parse(schema) : {}),
          apirequest: (newquery.length > 0 ? JSON.parse(newquery) : {}),
          response:sendtodbresponse[0],
          responseschema: JSON.parse(resschema),
          clientid: sessionStorage.getItem("clientid"),
          projectid: sessionStorage.getItem("projectid"),
          metadataid: sessionStorage.getItem("metadataId"),
          apiusage: template.apiusage,
          ispayload: ispayload

        },
      };
      var config = {
        method: "post",
        url: process.env.REACT_APP_API_URL_UPSERT,
        header: { "Content-Type": "application/json" },
        data: [sendtobackend],
      };
      axios(config)
        .then((res) => {
         if(resschema.length === 0){
          setOpen(true);
          setAlertStatus("warning");
          setAlertmsg("Response Schema Cannot be empty");
         }
         
         else if (res.status === 200 && res.data.Code === 1111) {
            setOpen1(true);
            setAlertStatus1("warning");
            setAlertmsg1(res.data.error);

          }
          else if(res.status!==200 && res.status!==201){
            // alert("giff")
            setOpen1(true);
            setAlertStatus1("warning");
            setAlertmsg1(res.data.error);
            setActiveStep(1)

          }
          else {
            setOpen1(true);
            setAlertStatus1("success");
            setAlertmsg1(
              "Data is edited Successfully "
            );
          }
        })
        .catch((err) => {
          console.error(err);
          setOpen(true);
          setAlertStatus(err.message,"error");
          
          // setAlertmsg1(err.message,"Oops Something went wrong");
        });

      }else{

        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg("Response Schema Cannot be empty");
      }
      localStorage.setItem("reload", "true");
    };


    const AddParameterInFilterObj = (
      inputresp,
      entityName,
      filterstrparamasArr
    ) => {
      var rstArr = inputresp.data.ast;
      var subNodeArr = rstArr[0].subNodes;
      for (var i = 0; i < subNodeArr.length; i++) {
        var Nodetype = subNodeArr[i].type;
        if (Nodetype == "for") {
          var forSubNode = subNodeArr[i].subNodes;
          var colName = forSubNode[1].name;
          if (colName == entityName) {
            var filterNode = subNodeArr[i + 1];
            var filterNodetype = filterNode.type;
            if (filterNodetype == "filter") {
              var filtersubNode = filterNode.subNodes;
              var resultqueryObj = new Object();
              var respfilter = Formfilterquerywithparam(
                filtersubNode,
                subNodeArr[i].type,
                resultqueryObj,
                filterstrparamasArr
              );
              respfilter["parentcondition"] = filtersubNode[0].type;
              return respfilter;
            }
          }
        }
      }
      // return "";
    };
    const AddParameterInFilter = (inputresp, filterstrparamasArr) => {
      var rstArr = inputresp.data.ast;
      var subNodeArr = rstArr[0].subNodes;
      for (var i = 0; i < subNodeArr.length; i++) {
        var Nodetype = subNodeArr[i].type;
        if (Nodetype == "filter") {
          var filtersubNode = subNodeArr[i].subNodes;
          var resultqueryObj = new Object();
          var respfilter = Formfilterquerywithparam(
            filtersubNode,
            subNodeArr[i].type,
            resultqueryObj,
            filterstrparamasArr
          );
          respfilter["parentcondition"] = filtersubNode[0].type;
          return respfilter;
        }
      }
      // return "";
    };

    const Formfilterquerywithparam = (
      filtersubNode,
      filterparentnodeType,
      resultqueryObj,
      filterstrparamasArr
    ) => {
      var query = "";
      if (filtersubNode.length > 0) {
        for (var i = 0; i < filtersubNode.length; i++) {
          var filtersubnodeType = filtersubNode[i].type;
          if (filtersubnodeType != "quantifier") {
            if (
              filtersubnodeType.includes("in") ||
              filtersubnodeType.includes("and") ||
              filtersubnodeType.includes("==") ||
              filtersubnodeType.includes("or")
            ) {
              var filterlogicalsubnode = filtersubNode[i].subNodes;
              Formfilterquerywithparam(
                filterlogicalsubnode,
                filtersubnodeType,
                resultqueryObj,
                filterstrparamasArr
              );
            } else {
              var fieldName = filtersubNode[i].name;
  
              var subnodetype = filtersubNode[i].type;
              var fieldrefsubNode = "";
              var reffieldName = "";
              if ("subNodes" in filtersubNode[i]) {
                fieldrefsubNode = filtersubNode[i].subNodes[0];
                reffieldName = fieldrefsubNode.name;
              }
  
              if (subnodetype != "value" && subnodetype != "array") {
                if (query.trim().startsWith("@")) {
                  query =
                    query +
                    " " +
                    filterparentnodeType +
                    " " +
                    reffieldName +
                    "." +
                    fieldName;
                } else if (!query.includes(filterparentnodeType)) {
                  if (reffieldName == "DOCUMENT") {
                    query =
                      query +
                      " " +
                      reffieldName +
                      "(" +
                      GetDocFunctionattfilter(fieldrefsubNode.subNodes) +
                      ")." +
                      fieldName +
                      " " +
                      filterparentnodeType +
                      " ";
                  } else if (reffieldName == undefined) {
                    var reffielddocsubnodes = fieldrefsubNode.subNodes;
                    var reffielddocsubnodesName = reffielddocsubnodes[0].name;
                    query =
                      query +
                      " " +
                      reffielddocsubnodesName +
                      "(" +
                      GetDocFunctionattfilter(reffielddocsubnodes[0].subNodes) +
                      ")[" +
                      reffielddocsubnodes[1].value +
                      "]." +
                      fieldName +
                      " " +
                      filterparentnodeType +
                      " ";
                  } else if (
                    query.trim().startsWith("@") ||
                    query.trim().startsWith("[@")
                  ) {
                    query =
                      query +
                      " " +
                      filterparentnodeType +
                      "" +
                      reffieldName +
                      "." +
                      fieldName +
                      " ";
                  } else {
                    query =
                      query +
                      " " +
                      reffieldName +
                      "." +
                      fieldName +
                      " " +
                      filterparentnodeType +
                      " ";
                  }
                } else {
                  query = query + " " + reffieldName + "." + fieldName + " ";
                }
              } else {
                var val = "";
                if (subnodetype == "array") {
                  val = filtersubNode[i].subNodes;
                  var arrval = new Array();
                  for (var k = 0; k < val.length; val++) {
                    arrval.push(val[k].value);
                  }
                  if (filtersubNode[i - 1] != undefined) {
                    filterstrparamasArr.push(filtersubNode[i - 1].name);
                    query = query + " [@" + filtersubNode[i - 1].name + "]";
                  } else {
                    //if value comes first
                    //  val=filtersubNode[i].value
                    filterstrparamasArr.push(filtersubNode[i + 1].name);
                    query = query + " [@" + filtersubNode[i + 1].name + "]";
                  }
                } else {
                  val = filtersubNode[i].value;
                  filterstrparamasArr.push(filtersubNode[i - 1].name);
                  if (filtersubNode[i - 1].name == "activestatus") {
                    query = query + true;
                  } else {
                    query = query + " @" + filtersubNode[i - 1].name;
                  }
                }
              }
            }
          }
        }
        if (query != "") {
          var rsltqurykeys = Object.keys(resultqueryObj);
          resultqueryObj["filter" + rsltqurykeys.length] = query;
          resultqueryObj["filterparam" + rsltqurykeys.length] =
            filterstrparamasArr;
        }
      }
  
      return resultqueryObj;
    };
    const GetDocFunctionattfilter = (inputsubnodes, resultquery) => {
      if (inputsubnodes.length > 0) {
        for (var i = 0; i < inputsubnodes.length; i++) {
          var subNodes = inputsubnodes[i].subNodes;
          for (var j = 0; j < subNodes.length; j++) {
            var fieldName = subNodes[j].name;
            var fieldRef = subNodes[j].subNodes[0].name;
            if (fieldRef == "DOCUMENT") {
              //query = query + " " + reffieldName + "(" + GetDocFunctionattfilter(fieldrefsubNode.subNodes) + ")." + " " + fieldName + " " + filterparentnodeType + " ";
              resultquery =
                fieldRef +
                "(" +
                GetDocFunctionattfilter(
                  subNodes[j].subNodes[0].subNodes,
                  resultquery
                ) +
                ")";
            }
            resultquery =
              (resultquery == undefined ? fieldRef : resultquery) +
              "." +
              fieldName;
          }
          return resultquery;
        }
      }
    };

    const converter = (value, resp, finalfilterquery, filterrespObj) => {
      
      var onlytDocFilter = JSON.parse(readquery);
      if (typeof onlytDocFilter.filter != "object") {
        onlytDocFilter.filter = finalfilterquery;
      }
      var rdobj = onlytDocFilter;
      console.log("rdobj", rdobj);
  
      if (Array.isArray(JSON.parse(readquery))) {
        for (var rd = 0; rd < rdobj.length; rd++) {
          let parsedvalue = rdobj[rd];
          console.log("parsed", parsedvalue.doc);
          let valuereplace1 = "";
          let valuereplace = "";
          if (parsedvalue.doc === undefined) {
            valuereplace = "";
          } else {
            valuereplace = parsedvalue.doc;
          }
          if (parsedvalue.filter !== undefined && parsedvalue.filter !== null) {
            valuereplace1 = parsedvalue.filter;
          }
  
          console.log(parsedvalue, valuereplace);
          console.log(valuereplace1);
          var rep = Object.keys(valuereplace);
          var rep1 = Object.keys(valuereplace1);
          ////
          for (var i = 0; i < rep.length; i++) {
            var objectArr = valuereplace[rep[i]];
            if (Array.isArray(valuereplace[rep[i]])) {
              console.log(typeof objectArr[0]);
              for (var j = 0; j < objectArr.length; j++) {
                replaceArray(objectArr[j], rep[i]);
                console.log("replace", replaceArray(objectArr[j], rep[i]));
              }
            } else {
              valuereplace[rep[i]] = "@" + rep[i];
            }
          }
          ///////
          console.log(valuereplace);
  
          rep1.map((e) => {
            var symbol = "@";
            return (valuereplace1[e] = symbol.concat(e));
          });
          if (readquery.length == 0) {
            setOpen(true);
            setAlertStatus("warning");
            setAlertmsg("Request box  is empty");
          }
  
          var parsed = JSON.parse(readquery);
          if (Array.isArray(parsed)) {
            var docfilter = { doc: parsed[0].doc, filter: parsed[0].filter };
            Object.keys(docfilter).map(
              (e) => docfilter[e] === undefined && delete docfilter[e]
            );
            console.log(docfilter);
          } else {
            var docfilter = { doc: parsed.doc, filter: parsed.filter };
            Object.keys(docfilter).map(
              (e) => docfilter[e] === undefined && delete docfilter[e]
            );
          }
  
          const schemas = toJsonSchema(docfilter.doc);
  
          var pretty = JSON.stringify(schemas, undefined, 4);
          var pretty1 = JSON.stringify(parsed, undefined, 4);
  
          setSchema(pretty);
          setReadquery(pretty1);
          console.log(schemas);
          console.log(valuereplace);
          console.log(valuereplace1);
  
          var newreadquery = {};
          console.log(newreadquery);
          if (parsedvalue.doc !== undefined) {
            Object.assign(parsedvalue, { doc: valuereplace });
          }
  
          if (parsedvalue.filter !== undefined && parsedvalue.filter !== null) {
            Object.assign(parsedvalue, { filter: valuereplace1 });
          }
          setNewQuery(JSON.stringify(parsedvalue, undefined, 4));
          // setNewQuery1(JSON.stringify(parsedvalue, undefined, 4));
        }
      } else {
        // alert("2");
        var parsedvalue = rdobj;
  
        // var valuereplace= parsedvalue.doc  ;
        var valuereplace1 = "";
        var valuereplace = "";
        if ((parsedvalue.doc === undefined, 4)) {
          valuereplace = "";
        } else {
          valuereplace = parsedvalue.doc;
        }
        if (parsedvalue.filter !== undefined && parsedvalue.filter !== null) {
          //  valuereplace1= parsedvalue.filter
  
          valuereplace1 = parsedvalue.filter;
        }
        console.log(parsedvalue);
        console.log(valuereplace1);
        var rep = Object.keys(valuereplace);
        var rep1 = "";
  
        if (typeof valuereplace1 == "object") {
          rep1 = Object.keys(valuereplace1);
        } else {
          // valuereplace1.includes("._id")
          // valuereplace1.split("&&","==")
          // valuereplace1.split("==")
          // let params = valuereplace1.split("==")
          // params[1]="@_id"
          // params.join("==")
        }
        for (var i = 0; i < rep.length; i++) {
          if (Array.isArray(valuereplace[rep[i]])) {
            var objectArr = valuereplace[rep[i]];
            for (var j = 0; j < objectArr.length; j++) {
              replaceArray(objectArr[j], rep[i]);
            }
          } else {
            valuereplace[rep[i]] = "@" + rep[i];
          }
        }
        console.log(valuereplace);
  
        if (typeof rep1 == "object") {
          rep1.map((e) => {
            var symbol = "@";
            return (valuereplace1[e] = symbol.concat(e));
          });
        } else {
          // let splitS = valuereplace1;
          //   let arr = splitS.split("==")
          //    arr[1] = "@_id"
          //    arr.join('==')
        }
  
        ///filter for requestschema
        var parsed = JSON.parse(readquery);
        var docfilter = { doc: parsed.doc, filter: parsed.filter };
        Object.keys(docfilter).map(
          (e) => docfilter[e] === undefined && delete docfilter[e]
        );
        console.log(docfilter);
        var schemas = "";
        if (typeof docfilter.filter == "object") {
          var filterparamschemaObj = new Object();
          var filterObjparamasArr = new Array();
          var fltrObjkeyArr = Object.keys(parsedvalue.filter);
          for (var s = 0; s < fltrObjkeyArr.length; s++) {
            var entityName = fltrObjkeyArr[s];
            var entityfieldValue = parsedvalue.filter[fltrObjkeyArr[s]];
            var updatedfilter = AddParameterInFilterObj(
              resp,
              entityName,
              filterObjparamasArr
            );
            var updatedfilterArr = Object.keys(updatedfilter);
            for (var h = 0; h < updatedfilterArr.length; h++) {
              if (updatedfilterArr[h].includes("filterparam")) {
                var filterparamArr = updatedfilter[updatedfilterArr[h]];
                for (var f = 0; f < filterparamArr.length; f++) {
                  filterparamschemaObj[filterparamArr[f]] =
                    "@" + filterparamArr[f];
                }
              }
            }
          }
          delete filterparamschemaObj.activestatus;
          schemas = toJsonSchema(filterparamschemaObj);
        } else {
          // var filterparamObj = AddParameterInFilter(docfilter.filter, parsed.entity);
          // schemas = toJsonSchema(docfilter.filter);
          delete filterrespObj.activestatus;
          schemas = toJsonSchema(filterrespObj);
        }
  
        var pretty = JSON.stringify(schemas, undefined, 4);
        var pretty1 = JSON.stringify(parsed, undefined, 4);
  
        setSchema(pretty);
        setReadquery(pretty1);
        console.log(schemas);
        console.log(valuereplace);
        console.log(valuereplace1);
  
        var newreadquery = {};
        console.log(newreadquery);
        if (parsedvalue.doc !== undefined) {
          Object.assign(parsedvalue, { doc: valuereplace });
        }
  
        if (parsedvalue.filter !== undefined && parsedvalue.filter !== null) {
          if (typeof parsedvalue.filter == "object") {
            var filterObjparamasArr = new Array();
            var fltrObjkeyArr = Object.keys(parsedvalue.filter);
            for (var s = 0; s < fltrObjkeyArr.length; s++) {
              var entityName = fltrObjkeyArr[s];
              var updatedfilter = AddParameterInFilterObj(
                resp,
                entityName,
                filterObjparamasArr
              );
              var updatefltrrespArr = Object.keys(updatedfilter);
              var finalquery = "";
              for (var m = 0; m < updatefltrrespArr.length; m++) {
                if (
                  updatefltrrespArr[m].includes("filter") &&
                  (!updatefltrrespArr[m].includes("filterparam") ||
                    updatefltrrespArr[m].includes("parentcondition"))
                ) {
                  var updatefltrrespObj = updatedfilter[updatefltrrespArr[m]];
                  var updatefltrrespparentcond = updatedfilter.parentcondition;
                  if (
                    finalquery.trim().startsWith("@") ||
                    updatefltrrespObj.trim().startsWith("@")
                  ) {
                    finalquery = finalquery + " " + updatefltrrespObj;
                  } else {
                    finalquery =
                      finalquery +
                      " " +
                      updatefltrrespObj +
                      " " +
                      updatefltrrespparentcond;
                  }
                }
              }
              finalquery = finalquery.replaceAll("compare ==", "==");
              finalquery = finalquery.replaceAll("logical and", "&&");
              finalquery = finalquery.replaceAll("logical or", "||");
              finalquery = finalquery.replaceAll("compare in", "IN");
              finalquery = finalquery.replaceAll("array compare ==", "ANY ==");
              finalquery = finalquery.replaceAll("array IN", "ANY IN");
              finalquery = finalquery.replaceAll("array ==", "ANY ==");
              if (
                finalquery.trim().endsWith("&&") ||
                finalquery.trim().endsWith("||") ||
                finalquery.trim().endsWith("in") ||
                finalquery.trim().endsWith("IN") ||
                finalquery.trim().endsWith("==")
              ) {
                finalquery = finalquery.substring(0, finalquery.length - 2);
              }
              if (finalquery.trim().endsWith("ANY")) {
                finalquery = finalquery
                  .trim()
                  .substring(0, finalquery.trim().length - 3);
              }
              parsedvalue.filter[entityName] = finalquery.replaceAll(
                "adqolc",
                ""
              );
              console.log("test");
            }
            Object.assign(parsedvalue, { filter: parsedvalue.filter });
          } else {
            //readResp.data.query
            // var parsedQueryObj=ArangoDBAPI.GetQueryInfo(readResp.data.query);
  
            var filterparamStr = parsedvalue.filter;
            //  AddParamsForObjfilter(filterparamStr)
            //AddParameterInFilterStr(parsedvalue.filter, parsed.entity);
            Object.assign(parsedvalue, { filter: filterparamStr });
          }
        }
  
        setNewQuery(JSON.stringify(parsedvalue, undefined, 4));
        //setNewQuery1(JSON.stringify(parsedvalue, undefined, 4));
      }
  
      return value;
    };
  


    ///first generate function//////
    const schemaConverter = (value) => {
    
      if (template.url === process.env.REACT_APP_PREFIX) {
        var isvalidjson = false;
        try {
          JSON.parse(readquery);
          isvalidjson = true;
        } catch (err) {}
  
        if (isvalidjson == false) {
          setOpen(true);
          setAlertStatus("warning");
          setAlertmsg("Enter valid query to Proceed");
        } else {
          var configapi = {
            method: "post",
            url: template.url + template.endpointpath,
            header: { "Content-Type": "application/json" },
            //data: Array.isArray(JSON.parse(readquery))?JSON.parse(readquery)[0]:JSON.parse(readquery),
            data: JSON.parse(readquery),
          };
          axios(configapi)
            .then((res) => {
              console.log("v", res.data);
  
              if (res.data.Code === 201) {
                var inputjson = JSON.parse(readquery);
  
                if (schema.length === 0 && inputjson.length === 0) {
                  converter(value);
                  setOpen(true);
                  setAlertStatus("warning");
                  setAlertmsg("something went wrong ,Try again");
                } else if (template.endpointpath === "upsert_document") {
                  converter(value);
                } else {
                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_API_URL,
                    header: { "Content-Type": "application/json" },
                    data: JSON.parse(readquery),
                  };
                  console.log(config);
                  axios(config).then((res) => {
                    ArangoDBAPI.GetQueryInfo(res.data.query, function (resp) {
                      var filterstrparamasArr = [];
                      var filterrespObj = {};
                      //return resp;
                      // console.log(resp);
                      //var readResp = ArangoDBAPI.ReadDocument(readquery);
                      var filterstrs = AddParameterInFilter(
                        resp,
                        filterstrparamasArr
                      );
  
                      var filtersObjKeys = Object.keys(filterstrs);
  
                      if (filtersObjKeys.length > 0) {
                        var finalfilterquery = "";
                        for (var m = 0; m < filtersObjKeys.length; m++) {
                          if (filtersObjKeys[m].includes("filterparam")) {
                            var filtersObjSTr = filterstrs[filtersObjKeys[m]];
                            if (filtersObjSTr.length > 0) {
                              for (var t = 0; t < filtersObjSTr.length; t++) {
                                filterrespObj[filtersObjSTr[t]] =
                                  filtersObjSTr[t];
                              }
                            }
                          }
                          if (
                            filtersObjKeys[m] != "parentcondition" &&
                            !filtersObjKeys[m].includes("filterparam")
                          ) {
                            var filtersObjSTr = filterstrs[filtersObjKeys[m]];
                            finalfilterquery =
                              finalfilterquery +
                              filtersObjSTr +
                              " " +
                              filterstrs["parentcondition"];
                          }
                        }
                        finalfilterquery = finalfilterquery.replaceAll(
                          "compare ==",
                          "=="
                        );
                        finalfilterquery = finalfilterquery.replaceAll(
                          "logical and",
                          "&&"
                        );
                        finalfilterquery = finalfilterquery.replaceAll(
                          "logical or",
                          "||"
                        );
                        finalfilterquery = finalfilterquery.replaceAll(
                          "compare in",
                          "IN"
                        );
                        finalfilterquery = finalfilterquery.replaceAll(
                          "array compare ==",
                          "ANY =="
                        );
                        finalfilterquery = finalfilterquery.replaceAll(
                          "array IN",
                          "ANY IN"
                        );
                        finalfilterquery = finalfilterquery.replaceAll(
                          "array ==",
                          "ANY =="
                        );
  
                        finalfilterquery = finalfilterquery.replaceAll(
                          "adqolc",
                          ""
                        );
                        finalfilterquery = finalfilterquery.substring(
                          0,
                          finalfilterquery.length - 2
                        );
                        if (finalfilterquery.trim().endsWith("ANY")) {
                          finalfilterquery = finalfilterquery
                            .trim()
                            .substring(0, finalfilterquery.trim().length - 3);
                        }
                      }
                      converter(value, resp, finalfilterquery, filterrespObj);
                    });
                  });
                }
                setOpen(true);
                setAlertStatus("success");
                setAlertmsg("Schema generated successfully");
              } else {
                setOpen(true);
                setAlertStatus("warning");
                setAlertmsg(res.data.error || res.data.Result);
              }
            })
            .catch((err) => {
              setOpen(true);
              setAlertStatus("error");
              setAlertmsg(
                "Please enter proper URL & httpmethod",
                err.message
              );
            });
        }
      } else {
        
        var parsedvalue = JSON.parse(readquery);
        var valuereplace1 = "";
  
        if (parsedvalue !== undefined && parsedvalue !== null) {
          valuereplace1 = parsedvalue;
        }
        var rep1 = Object.keys(valuereplace1);
  
        rep1.map((e) => {
          var symbol = "@";
          return (valuereplace1[e] = symbol.concat(e));
        });
  
        var parsed = JSON.parse(readquery);
        const schemas = toJsonSchema(parsed);
        var pretty = JSON.stringify(schemas, undefined, 4);
        var pretty1 = JSON.stringify(parsed, undefined, 4);
        setSchema(pretty);
        // setNewQuery(pretty1);
  
        if (parsedvalue !== undefined && parsedvalue !== null) {
          Object.assign(parsedvalue, valuereplace1);
        }
  
        console.log("[0]", JSON.stringify(parsedvalue) !== '["@0"]');
        if (JSON.stringify(parsedvalue) !== '["@0"]') {
          setNewQuery(JSON.stringify(parsedvalue, undefined, 4));
        } else {
          setOpen(true);
          setAlertStatus("error");
          setAlertmsg("Something went wrong ! please check your url");
        }
  
        return value;
      }
    };

   

    const replaceArray = (inputval, parentfield) => {
    
      console.log(
        "%c dadadd",
        " background-color: black;color: white;font: 24px ;font-weight:bolder",
        typeof inputval === "string",
        parentfield
      );
      if (typeof inputval !== "string") {
        var inputobjKeys = Object.keys(inputval);
        for (var i = 0; i < inputobjKeys.length; i++) {
          if (Array.isArray(inputval[inputobjKeys[i]])) {
            var objectArr = inputval[inputobjKeys[i]];
            for (var j = 0; j < objectArr.length; j++) {
              var parentmerge;
              if (inputobjKeys[i] === parentfield) {
                parentmerge = inputobjKeys[i];
              } else {
                parentmerge = parentfield + "." + inputobjKeys[i];
              }
              replaceArray(objectArr[j], parentmerge);
            }
          } else {
            inputval[inputobjKeys[i]] = "@" + parentfield + "." + inputobjKeys[i];
          }
        }
      }
      return inputval;
    };

    const converter2 = () => {
      const check = JSON.parse(apidata, undefined, 4)
      const arrayapidata = [check]

      const schemaz = toJsonSchema(arrayapidata);

      var pretty = JSON.stringify(schemaz, undefined, 4);
      setReschema(pretty);

      // console.log(schemas);
      if (pretty.length === 0) {

        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg(
          " schema generation failed"
        );

      } else if (pretty.length > 0) {
        setOpen(true);
        setAlertStatus("success");
        setAlertmsg(
          " schema generated successfully"
        );
      }
    };


    const requestFunction = (e) => {
      console.log(e.target.value)
      setReadquery(e.target.value)
      if (readquery.length === 0) {
        setSchema("")
        setNewQuery("")
      }
    }
    return (
      <Box sx={{ width: "95%", margin: "auto" }}>
        <div>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}
              onClick={() => {
                navigation(-1);
              }}
            >
              <HomeIcon style={{ color: "black" }} />
              <h6>Home</h6>
            </div>
          </span>
        </div>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {activeStep + 1 === 1 && (
              <Grid container direction={"row"} spacing={2}>

                <Grid item xs={6}>
                  <Stack direction={"column"}>
                    <label style={{ padding: "10px" }}>
                      API Name<span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      required
                      style={{ padding: "10px" }}
                      value={template.apiname}
                      onChange={(e) =>
                        setTemplate({ ...template, apiname: e.target.value })
                      }
                    ></input>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={"column"}>

                    <label style={{ padding: "10px" }}>
                      API Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      style={{ padding: "10px" }}
                      placeholder="Select"
                      value={template.apitype}
                      onChange={(e) =>{
                        setTemplate({ ...template, apitype: e.target.value })
                        e.target.value==="Arango"&&
                        handleFetch();
                          handleFetch1()
                      
                      }
                      }
                    >
                      <option value={""} selected disabled>Select</option>
                      <option value={"Arango"}>Arango</option>
                      <option value={"http"}>http</option>
                      <option value={"https"}>https</option>

                    </select>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={"column"}>

                    <label style={{ padding: "10px" }}>
                      Http Method Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      style={{ padding: "10px" }}
                      placeholder="Select"
                      value={template.httpmethod}
                      onChange={(e) =>{
                        setTemplate({ ...template, httpmethod: e.target.value })
                        setispayload(false)
                      }}
                    >
                     
                          <option value={""} selected disabled>Select</option>
                          <option value={"Get"}>Get</option>
                          <option value={"Post"}>Post</option>
                       


                    </select>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={"column"}>

                  <label style={{ padding: "10px" }}>
                      URL<span style={{ color: "red" }}>*</span>
                    </label>


                    {template.apitype === "Arango" ?

                      <Stack direction={"row"}>
                        <select
                          value={template.env}
                          required
                          onChange={(e) => setTemplate({ ...template, env: e.target.value, url: `https://arangodbservice.${e.target.value?.toLowerCase()}.ainqaplatform.in/api/` })} >
                            <option value={""}  disabled>Select</option>

                          {
                            fetchdata?.map(l =>{
                              console.log("techk",`https://arangodbservice.${l?.description?.toLowerCase()}.ainqaplatform.in/api/`===template.url?true:false,`https://arangodbservice.${l.description?.toLowerCase()}.ainqaplatform.in/api/`,template.url)
                              return(
                              <option value={l.description} selected={`https://arangodbservice.${l?.description?.toLowerCase()}.ainqaplatform.in/api/`===template.url?true:false}>{l?.description}</option>
                               )} )
                          }

                        </select>
                        <input
                          required
                          style={{ padding: "10px", width: "525px" }}
                          value={template.url}
                          onChange={(e) =>
                            setTemplate({ ...template, url: e.target.value })
                          }>
                        </input>
                      </Stack>

                      :

                      <input
                        required
                        style={{ padding: "10px" }}
                        value={template.url}
                        onChange={(e) =>
                          setTemplate({ ...template, url: e.target.value })
                        }
                      ></input>}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={"column"}>


                  <label style={{ padding: "10px" }}>
                      End point Path<span style={{ color: "red" }}>*</span>
                    </label>


                    {template.apitype === "Arango" ?
                      <select onChange={(e) => setTemplate({ ...template, endpointpath: e.target.value, })}
                        value={template.endpointpath}
                        style={{ padding: "10px" }}

                      >
                        <option value={""}  disabled>Select</option>

                        {
                          fetchdata1.map(l =>{
                            console.log("check",template.endpointpath===l.description?true:false,template.endpointpath,l.description)
                            return(
                            <option value={l.description} selected={template.endpointpath===l.description?true:false}>{l.description}</option>
                            )})
                        }

                      </select>
                      :
                      <>
                        <input
                          required
                          style={{ padding: "10px" }}
                          value={template.endpointpath}
                          onChange={(e) => setTemplate({ ...template, endpointpath: e.target.value, })}>
                        </input></>}

                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction={"column"}>

                    <label style={{ padding: "10px" }}>
                      API Usage<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      style={{ padding: "10px" }}
                      placeholder="Select"
                      value={template.apiusage}
                      onChange={(e) =>
                        setTemplate({ ...template, apiusage: e.target.value })
                      }
                    >
                      <option value={""} selected disabled>Select</option>
                      <option value={"Global"}>Global</option>
                      <option value={"Project"}>Project</option>
                    </select>
                  </Stack>
                </Grid>
                <Grid direction={"row"}>
                  <FormControlLabel style={{ marginLeft: "30px" ,marginTop:"30px"}} control={<Switch checked={ispayload} color="success" onChange={handleSwitch} name="Payload" disabled={template.httpmethod==="Get"?true:false}
/>} label="Payload" />
                  <FormHelperText style={{ marginLeft: "40px" }}>Turn On to add Payload</FormHelperText></Grid>

              </Grid>
            )}
            {activeStep + 1 === 2 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>REQUEST</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={Object.keys(readquery)?.length==0?"":readquery}
                      onChange={(e) =>
                        requestFunction(e)
                        // setTemplate1({ ...template1, endpointpath: e.target.value })
                      }
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 30px",
                    }}
                  >
                    <Button disabled={readquery?.length === 0 && true} onClick={() => schemaConverter()} variant="contained" sx={{ color: "white", backgroundColor: "#001f59" }} > Generate</Button>

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>REQUEST SCHEMA</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={readquery.length === 0 ? "" : schema}
                      onChange={
                        (e) => setSchema(e.target.value)
                        // setTemplate1({ ...template1, endpointpath: e.target.value })
                      }
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div><div
                    style={{
                      display: "flex",
                      marginLeft: "10px",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label> API REQUEST</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={readquery.length === 0 ? "" : newquery}
                      onChange={(e) => {
                        onchangeres(e.target.value);
                      }}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>


                </div>
              </div>
            )}
            {activeStep + 1 === 3 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>API RESPONSE</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={apidata}
                      onChange={(e) => {
                        setApidata(e.target.value);
                      }}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 30px",
                    }}
                  >
                    <Button onClick={() => converter2()} disabled={ apidata?.length=== 0 && true} variant="contained" sx={{ color: "white", backgroundColor: "#001f59" }} > Generate</Button>

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>RESPONSE SCHEMA</label>

                    <textarea
                      style={{ padding: "10px" }}
                      value={apidata?.length === 0 ? "" : resschema}
                      onChange={(e) => setReschema(e.target.value)}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                </div>
              </div>
            )}

            <Divider style={{ margin: "10px 0" }} variant="middle" />
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? (
              <Button onClick={() => handleFinish()} disabled={apidata?.length === 0 && true} >Finish</Button>
            ) : (
              <Button onClick={() => handleNext()}>Next</Button>
            )}
          </Box>
        </React.Fragment>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alerts
            onClose={handleClose}
            severity={alertstatus}
            sx={{ width: "100%" }}
          >
            {alertmsg}
          </Alerts>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open1}
          autoHideDuration={2000}
          onClose={handleClose1}
        >
          <Alerts

            onClose={handleClose1}
            severity={alertstatus1}
            sx={{ width: "100%" }}
          >
            {alertmsg1}
          </Alerts>
        </Snackbar>
      </Box>
    );
  }
  export default Stepform;