import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./homepage";
import Stepform from "./stepper";
import Stepperedit from "./stepperedit";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { Card, Grid, Stack } from "@mui/material";
import { Alert } from "./Context/index.js";
function Home() {
  const alertforhome = React.useContext(Alert)
  localStorage.setItem("reload", "");
  const [projectName, SetprojectName] = React.useState("");
  const [clientName, SetclientName] = React.useState("");
  const [pageerr, setPageerr] = React.useState(false);
  localStorage.setItem("url", window.location.search);
  let params = new URLSearchParams(window.location.search);
  console.log("awdadawd", localStorage.getItem("reload"));
  if (localStorage.getItem("reload") === "true") {
    console.log("true");
  }
  if (params.get("metadata_id")) {
    sessionStorage.setItem("metadataId", params.get("metadata_id"));
    sessionStorage.setItem("token", params.get("token"));
  }
  var metadataid = sessionStorage.getItem("metadataId");
  React.useEffect(() => {
    // window.location.reload(true);
    if (metadataid === null) {
      setPageerr(true);
    } else {
      setPageerr(false);
    }
    var datas = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: "projectvstools",
      filter: `projectvstools.metadataid =='${metadataid}'`,
      return_fields: "projectvstools",
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      header: { "content-Type": "application/json" },
      data: datas,
    };

    axios(config)
      .then((res) => {
        console.log("res");
        sessionStorage.setItem("clientid", res.data.result[0].clientid);
        sessionStorage.setItem("projectid", res.data.result[0].projectid);
        sessionStorage.setItem("databasename", res.data.result[0].dbname);
        var data1 = {
          db_name: process.env.REACT_APP_DB_NAME,
          entity: "clients",
          filter: `clients._id =='${sessionStorage.getItem("clientid")}'`,
          return_fields: "clients",
        };
        var config1 = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "content-Type": "application/json" },
          data: data1,
        };
        axios(config1).then((res) => {
          console.log(res.data.result[0].clientname);
          SetclientName(res.data.result[0].clientname);
          // sessionStorage.setItem("clientname", res.data.result[0].clientname);
        });
        var data2 = {
          db_name: process.env.REACT_APP_DB_NAME,
          entity: "projects",
          filter: `projects._id =='${sessionStorage.getItem("projectid")}'`,
          return_fields: "projects",
        };

        var config2 = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "content-Type": "application/json" },
          data: data2,
        };
        axios(config2).then((res) => {
          console.log(res.data.result[0].projectname);
          SetprojectName(res.data.result[0].projectname);
          // sessionStorage.setItem("projectname", res.data.result[0].projectname);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [metadataid]);

  return (
    <>
      {pageerr ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            backgroundColor: "white",
          }}
        >
          <Card
            style={{
              width: "50%",
              height: "50%",
              margin: "auto",
              display: "flex",
              boxShadow: "0 0 15px white",
              borderRadius: "20px",
              backgroundColor: "rgba(206, 17, 38, 0.05)",
            }}
          >
            <Stack direction="row" spacing={10} alignItems="center">
              <ErrorIcon
                variant=""
                fontSize="large"
                color="error"
                style={{ margin: "auto 0 auto 15px" }}
              />

              <Typography
                color="gray"
                style={{
                  margin: "auto 0 auto 30px",
                  fontSize: "50px",
                  fontFamily: "serif",
                  fontStyle: "normal",
                  fontWeight: "20%",
                }}
              >
                500 something went wrong
              </Typography>
            </Stack>

            {/* <Button startIcon={<ArrowBackIosIcon fontSize="large"/>} size="medium" variant="contained" color="primary" onClick={()=>this.handleback()} ><Typography>GO back</Typography></Button> */}
          </Card>
        </div>
      ) : (
        <>
          

<Grid container direction="row"  spacing={5}>
            <Grid item xs={3}>

            <Typography  fontSize={25}  style={{ color: "#00205c",marginLeft:"25px" ,fontWeight:"bold"   }}>Service Registry</Typography>
            </Grid>

            <Grid item xs={2}>
            <Typography
                  style={{
                    backgroundColor: "#00205c",
                    color: "white",
                    padding: "2px",
                    textAlign: "center",
                    margin: "5px",
                    borderRadius:"5px"
                  }}
                >
                  Client Name
                </Typography>
                <p style={{ margin: "0", textAlign: "center" }}>{clientName}</p>
            </Grid>
            <Grid item xs={2}>

            <Typography
                  style={{
                    backgroundColor: "#00205c",
                    color: "white",
                    padding: "2px",
                    textAlign: "center",
                    margin: "5px",
                    borderRadius:"5px"
                  }}
                >
                  Project Name
                </Typography>
                <Typography style={{ margin: "0", textAlign: "center" }}>
                  {projectName}
                </Typography>
            </Grid>
            <Grid item xs={2}>  
            
            <Typography
                  style={{
                    backgroundColor: "#00205c",
                    color: "white",
                    padding: "2px",
                    textAlign: "center",
                    margin: "5px",
                    borderRadius:"5px"
                  }}
                >
                  Database Name
                </Typography>
                <Typography  style={{ margin: "0", textAlign: "center" }}>
                  {sessionStorage.getItem("databasename")}
                </Typography>
            </Grid>
            


          </Grid>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/steps" element={<Stepform />} />
              <Route path="/edit" element={<Stepperedit />} />
            </Routes>
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default Home;
